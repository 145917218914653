<template>
  <section class="xa-container xa-container--hasFoot">
    <div class="xa-cell__box">
      <h3>
        照片
        <router-link
          :to="{ query: { action: 'notice' } }"
          class="xa-txt-blue xa-txt-14 fr"
          >说明</router-link
        >
      </h3>
      <p class="xa-txt-12">请拍摄物料全貌及其二维码，提交最少两张图片。</p>
      <p class="xa-txt-12">
        若设备二维码与提交的序列号不符，极飞有权不通过审核。
      </p>
    </div>
    <div class="xa-cell__box xa-bg-white">
      <van-uploader :after-read="afterRead" v-model="images" multiple />
    </div>
    <EnsureFootBtn
      buttonText="保存并返回"
      :disabled="submitBtnDisabled"
      @submit="onSubmit"
    />
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import uploadMix from '@/mixins/upload'
import { Uploader } from 'vant'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import resource from '@/controllers/resource'
let hasShowNotice = false
export default {
  name: 'loss-image-desc',
  mixins: [basePage, uploadMix],
  components: {
    EnsureFootBtn,
    VanUploader: Uploader
  },
  config: {
    title: '图片描述'
  },
  data() {
    return {
      images: [],
      cb: null
    }
  },
  computed: {
    submitBtnDisabled() {
      return this.images.length === 0
    }
  },
  methods: {
    async afterRead(file) {
      const result = await this.uploadMix_afterRead(file)
      if (result === -1) {
        this.images.pop()
      }
    },
    canContinueCheckUpload() {
      if (this.images.find(item => item.status !== 200)) {
        this.$dialog.alert({
          message: '有图片还在上传中，请等待上传完再操作！'
        })
        return false
      }
      return true
    },
    async onSubmit() {
      const oldGuids = this.$route.params.guids || []
      const guids = this.images.map(item => item.guid)
      if (oldGuids.join('-') !== guids.join('-')) {
        await this.cb(guids)
      }
      setTimeout(() => {
        this.$router.go(-1)
      }, 300)
    },
    async initUpladImg({ guids }) {
      if (Array.isArray(guids) === false) return
      if (guids.length <= 0) return
      const list = await this.$_fetchDataWidthUi(
        resource.postQiniuKey2Path(guids)
      )
      this.images = list.map(item => {
        return {
          url: item.thumb_url, // 展示的图片
          guid: item.uid, // 后台需要的guid
          status: 200, // 状态
          message: '上传成功'
        }
      })
    }
  },
  mounted() {
    const { params } = this.$route
    this.initUpladImg(params)
    const { cb } = params
    if (!cb) {
      this.$router.go(-1)
    } else {
      this.cb = Object.freeze(cb)
      if (hasShowNotice === false) {
        hasShowNotice = true
        setTimeout(() => {
          this.$router.push({
            query: { action: 'notice' }
          })
        }, 300)
      }
    }
  }
}
</script>
