<template>
  <section class="xa-container">
    <keep-alive include="DescImage,loss-image-desc">
      <component :is="view"></component>
    </keep-alive>
  </section>
</template>
<script>
import Desc from './Loss-Image-Desc.vue'
import Notice from './Loss-Image-Notice.vue'
export default {
  name: 'loss-image',
  components: {
    DescImage: Desc,
    NoticeImage: Notice
  },
  data() {
    return {
      view: 'DescImage'
    }
  },
  watch: {
    $route(route) {
      window.console.log(route)
      window.console.log(route.query.action)
      this.view = route.query.action === 'notice' ? 'NoticeImage' : 'DescImage'
    }
  }
}
</script>
