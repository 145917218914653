<template>
  <section class="xa-container xa-container--hasFoot">
    <div class="container-box">
      <div>
        <h3>
          提交定损照片要求
        </h3>
        <h4>上传照片要求</h4>
        <p>
          请按要求如实上传损坏物料的照片以便定损，具体要求如下：<br />
          1、无论结构件物料还是非结构件物料，均需拍摄损坏部位照片，该照片需清晰、完整；<br />
          2、配件有序列号或二维码的，必须拍摄清晰的序列号或二维码照片。若该配件无序列号或二维码的，则需拍摄清晰、完整的损坏部位照片。
        </p>
        <h4>
          上传照片格式
        </h4>
        <h5>格式一：</h5>
        <p>
          如需定损的物料的损坏数量小于二，原则上至少拍摄两张照片并上传，一张为损坏物料整体照片（图1），另一张为损坏物料序列号或二维码照片（图2），如损坏的物料没有序列号或二维码的，仅需拍摄一张损坏部位特写照片。
        </p>
        <h5>以螺旋桨为例：</h5>
        <p>
          <img
            class="container-box__img"
            src="../..//assets/loss-notice-1.png"
            alt=""
            srcset=""
          />
          <span>图1：损失物料整体照片</span>
          <img
            class="container-box__img"
            src="../..//assets/loss-notice-2.png"
            alt=""
            srcset=""
          />
          <span> 图2：损失物料二维码照片 </span>
        </p>
        <h5>
          格式二：
        </h5>
        <p>
          如需定损的物料的损坏数量大于或等于二，请将所有需定损的物料摆放整齐后拍摄一张照片（如图3），另需将所有损坏物料的序列号或二维码单独拍摄特写照片（如图4、5、6）并上传。如损坏的物料没有序列号或二维码请拍摄损坏部位特写照片。如损失的物料没有序列号或二维码的，请逐一拍摄损坏部位的特写照片。
        </p>
        <h5>以螺旋桨为例：</h5>
        <p>
          <img
            class="container-box__img"
            src="../..//assets/loss-notice-3.png"
            alt=""
            srcset=""
          />
          <span> 图3：损失物料摆放整齐的整体照片</span>
          <img
            class="container-box__img"
            src="../..//assets/loss-notice-4.png"
            alt=""
            srcset=""
          />
          <span> 图4：损失物料二维码照片1</span>
          <img
            class="container-box__img"
            src="../..//assets/loss-notice-5.png"
            alt=""
            srcset=""
          />
          <span>图5：损失物料二维码照片2 </span>
          <img
            class="container-box__img"
            src="../..//assets/loss-notice-6.png"
            alt=""
            srcset=""
          />
          <span>图6：损坏物料二维码照片3</span>
        </p>
        <h5>注意事项：</h5>
        <p>
          1、如定损物料与实际损坏物料存在不符之情形，将被视为骗取保障服务配件行为。极飞对此种骗取保障服务配件的行为保留追诉的权利，由此引发的全部经济和法律责任将由违规的分销商/经销商/用户自行承担。<br />
          2、若分销/经销商提交的定损信息（包括但不限于照片、文字描述等）不符合定损要求的，极飞有权退审或要求分销/经销商重新提交，由此导致的全部经济及法律责任，由分销/经销商自行承担。
        </p>
      </div>
    </div>
    <EnsureFootBtn
      buttonText="我知道了"
      :disabled="submitBtnDisabled"
      @submit="onSubmit"
    />
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import EnsureFootBtn from '@/components/EnsureFootBtn'
export default {
  name: 'loss-image-notice',
  mixins: [basePage],
  components: {
    EnsureFootBtn
  },
  config: {
    title: '坏件照片说明'
  },
  data() {
    return {
      images: [],
      cb: null
    }
  },
  computed: {
    submitBtnDisabled() {
      return false
    }
  },
  methods: {
    async onSubmit() {
      setTimeout(() => {
        this.$router.go(-1)
      }, 300)
    }
  }
}
</script>
<style lang="scss" scoped>
.container-box {
  padding: 15px;
  font-size: 15px;
  &__cell {
    position: relative;
    margin-top: 15px;
  }
  .cell__text {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 16px;
    margin: 0 auto;
    background-color: rgba(255, 137, 137, 0.39);
    border-radius: 35px;
    font-size: 15px;
    font-weight: bold;
    color: #3a3a3a;
    text-align: center;
    line-height: 33px;
    white-space: nowrap;
  }
  &__text {
    margin: 8px 0;
  }
  &__img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-color: #fff;
  }
  h3 {
    font-size: 18px;
    text-align: center;
  }
  h4 {
    padding: 12px 0;
    font-size: 16px;
    font-weight: bold;
  }
  h5 {
    padding: 8px 0;
    font-size: 15px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
  }
  span {
    display: block;
    padding: 4px 0;
    text-align: center;
  }
}
</style>
